import Images from '../../components/Common/Images'
import messages from '../../assets/messages'
import {companies, regulators, countries, corporateSteps, clientTypes, tradingStatuses, regions, localDepositorStatuses} from '@bdswiss/common-enums'
import {map, filter, isNil, toLower, isObject, isEmpty, get, reduce} from 'lodash'
import moment from 'moment'
import {disableDepositWithSpecificCountries, momentDuration} from './general'
import {ReactComponent as SettingsIcon} from '../../assets/images/settings_gear_icon.svg'
import {ReactComponent as ProfileIcon} from '../../assets/images/user_avatar_300.svg'
import {ReactComponent as LinkIcon} from '../../assets/images/transfer_sync_arrows_300.svg'
import {ReactComponent as CopyTradingIcon} from '../../assets/images/trade_300.svg'
import {ReactComponent as AppleIcon} from '../../assets/images/app_store_filled.svg'
import {ReactComponent as PlayStoreIcon} from '../../assets/images/google_play_filled.svg'
import {ReactComponent as ApkIcon} from '../../assets/images/android_APK_filled.svg'
import {ReactComponent as FacebookIcon} from '../../assets/images/facebook.svg'
import {ReactComponent as FacebookIconDark} from '../../assets/images/facebook-dark.svg'
import {ReactComponent as InstagramIcon} from '../../assets/images/instagram.svg'
import {ReactComponent as InstagramIconDark} from '../../assets/images/instagram-dark.svg'
import {ReactComponent as LinkedinIcon} from '../../assets/images/linkedin.svg'
import {ReactComponent as LinkedinIconDark} from '../../assets/images/linkedin-dark.svg'
import {ReactComponent as TelegramIcon} from '../../assets/images/telegram.svg'
import {ReactComponent as TelegramIconDark} from '../../assets/images/telegram-dark.svg'
import {ReactComponent as XIcon} from '../../assets/images/x.svg'
import {ReactComponent as XIconDark} from '../../assets/images/x-dark.svg'
import {ReactComponent as YoutubeIcon} from '../../assets/images/youtube.svg'
import {ReactComponent as YoutubeIconDark} from '../../assets/images/youtube-dark.svg'
import {ReactComponent as TiktokIcon} from '../../assets/images/tiktok.svg'
import {ReactComponent as TiktokIconDark} from '../../assets/images/tiktok-dark.svg'
import config from '../../config'

export const europeCountries= map(filter(countries, 'isEU'), 'value')

export const officialOnlineTradingSponsors = [
  {
    src: 'mercedesCup',
    alt: 'Mercedes Cup'
  },
  {
    src: 'dpWorldTourChampionship',
    alt: 'DP World Tour Championship'
  }
]

export const tabStats ={
  2012:{
    registeredUsers: '19,998',
    avgTrading: '1,066,291',
    depositMethods: '6',
    avgWithdrawal: '97hrs',
    supportTime: '32m 44s'
  },
  2013:{
    registeredUsers: '84,353',
    avgTrading: '3,282,822',
    depositMethods: '8',
    avgWithdrawal: '96hrs',
    supportTime: '15m 3s'
  },
  2014:{
    registeredUsers: '240,496',
    avgTrading: '9,308,132',
    depositMethods: '14',
    avgWithdrawal: '38hrs',
    supportTime: '8m 12s'
  },
  2015:{
    registeredUsers: '431,867',
    avgTrading: '14,346,207',
    depositMethods: '32',
    avgWithdrawal: '29hrs',
    supportTime: '5m 36s'
  },
  2016:{
    registeredUsers: '585,104',
    avgTrading: '1,758,909,310',
    depositMethods: '87',
    avgWithdrawal: '22hrs',
    supportTime: '3m 40s'
  },
  2017:{
    registeredUsers: '936,639',
    avgTrading: '4,824,558,933',
    depositMethods: '55',
    avgWithdrawal: '12hrs',
    supportTime: '3m 11s'
  },
  2018:{
    registeredUsers: '1,043,859',
    avgTrading: '11,448,594,083',
    depositMethods: '148',
    avgWithdrawal: '5hrs',
    supportTime: '35s'
  },
  2019:{
    registeredUsers: '1,198,295',
    avgTrading: '35,703,368,472',
    depositMethods: '151',
    avgWithdrawal: '5hrs',
    supportTime: '35s',
  },
  2020:{
    registeredUsers: '1,638,390',
    avgTrading: '73,405,180,902',
    depositMethods: '151',
    avgWithdrawal: '9hrs',
    supportTime: '50s',
  },
  2021:{
    registeredUsers: '',
    avgTrading: '',
    depositMethods: '',
    avgWithdrawal: '',
    supportTime: '',
    src: Images['spaceship.png'],
    alt: '2021'
  },
}

export const companiesDescription ={
  bdSwissMauritius:{
    regulatedBy: {
      message: messages.regulatorEntity,
      values: {
        regulator: regulators[companies.bdSwissMauritius.regulator].label
      }
    },
    leverageUpTo: {
      message: messages.leverageUp,
      values: {
        leverage: 500
      }
    },
    insurance: messages.insurance,
    negativeBalance: messages.negativeBalance,
    wealthManagement: messages.wealthManagement,
  },
  bdSwiss:{
    regulatedBy: {
      message: messages.regulatorEntity,
      values: {
        regulator: regulators[companies.bdSwiss.regulator].label
      }
    },
    leverageUpTo: {
      message: messages.leverageUp,
      values: {
        leverage: 30
      }
    },
    icfProtection: messages.icfProtection,
    negativeBalance: messages.negativeBalance,
  },
  bdSwissVanuatu:{
    regulatedBy: {
      message: messages.regulatorEntity,
      values: {
        regulator: regulators[companies.bdSwissVanuatu.regulator].label
      }
    },
    leverageUpTo: {
      message: messages.leverageUp,
      values: {
        leverage: 500
      }
    },
    negativeBalance: messages.negativeBalance,
  },
}

export const accountTypeStats ={
  basic: {
    details: {
      minDepositAmount: {
        default: 100,
        bdSwissMauritius: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
        bdSwissVanuatu: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
        swissMarketsMauritius: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
        swissMarketsVanuatu: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
      },
      averageSpread: {
        default: 1.3,
        bdSwissMauritius: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
        bdSwissVanuatu: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
        swissMarketsMauritius: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
        swissMarketsVanuatu: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
      }
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
  },
  black: {
    details: {
      minDepositAmount: {
        default: 3000,
        bdSwissMauritius: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
        bdSwissVanuatu: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
        swissMarketsMauritius: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
        swissMarketsVanuatu: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
      },
      averageSpread: {
        default: 1.0,
        bdSwissMauritius: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
        bdSwissVanuatu: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
        swissMarketsMauritius: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
        swissMarketsVanuatu: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
      },
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      indices: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['priorityServices', 'dailyAnalysis', 'personalAccountManager', 'accessExclusiveWebinars', 'autochartistPerformanceStats', 'vipTradingAlerts'],
  },
  raw:{
    details: {
      minDepositAmount: {
        default: 5000,
        bdSwissMauritius: {
          default: 500,
          bh: 50000,
          kw: 50000,
          om: 50000,
          qa: 50000,
          sa: 50000,
          ae: 50000,
          eg: 50000,
          iq: 50000,
          il: 50000,
          jo: 50000,
          lb: 50000,
          ye: 50000,
          ps: 50000
        },
        bdSwissVanuatu: {
          default: 500,
          bh: 50000,
          kw: 50000,
          om: 50000,
          qa: 50000,
          sa: 50000,
          ae: 50000,
          eg: 50000,
          iq: 50000,
          il: 50000,
          jo: 50000,
          lb: 50000,
          ye: 50000,
          ps: 50000
        },
        swissMarketsMauritius: {
          default: 500,
          bh: 50000,
          kw: 50000,
          om: 50000,
          qa: 50000,
          sa: 50000,
          ae: 50000,
          eg: 50000,
          iq: 50000,
          il: 50000,
          jo: 50000,
          lb: 50000,
          ye: 50000,
          ps: 50000
        },
        swissMarketsVanuatu: {
          default: 500,
          bh: 50000,
          kw: 50000,
          om: 50000,
          qa: 50000,
          sa: 50000,
          ae: 50000,
          eg: 50000,
          iq: 50000,
          il: 50000,
          jo: 50000,
          lb: 50000,
          ye: 50000,
          ps: 50000
        },
      },
      averageSpread: 0.0,
    },
    commission: {
      forexMetals: {
        value: '$5',
        text: 'equivalentPerLot',
        cn: {
          value: '$5',
        }
      },
      energies: {
        value: '$5',
        text: 'equivalentPerLot',
        cn: {
          value: '$5',
        }
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['rawSpreadsFromZero', 'priorityServices', 'dailyAnalysis', 'personalAccountManager', 'accessExclusiveWebinars', 'autochartistPerformanceStats', 'vipTradingAlerts'],
  },
  zero :{
    details: {
      minDepositAmount: {
        default: 5000,
        bdSwissMauritius: {
          default: 100,
          bh: 20000,
          kw: 20000,
          om: 20000,
          qa: 20000,
          sa: 20000,
          ae: 20000
        },
        bdSwissVanuatu: {
          default: 100,
          bh: 20000,
          kw: 20000,
          om: 20000,
          qa: 20000,
          sa: 20000,
          ae: 20000
        },
        bdSwissComoros: {
          default: 100,
          bh: 20000,
          kw: 20000,
          om: 20000,
          qa: 20000,
          sa: 20000,
          ae: 20000
        },
        swissMarketsMauritius: {
          default: 100,
          bh: 20000,
          kw: 20000,
          om: 20000,
          qa: 20000,
          sa: 20000,
          ae: 20000
        },
        swissMarketsVanuatu: {
          default: 100,
          bh: 20000,
          kw: 20000,
          om: 20000,
          qa: 20000,
          sa: 20000,
          ae: 20000
        },
      },
      averageSpread: 0.3
    },
    commission: {
      forexMetals: {
        value: '$6',
        text: 'equivalentPerLot',
        cn: {
          value: '$6',
        }
      },
      energies: {
        value: '$6',
        text: 'equivalentPerLot',
        cn: {
          value: '$6',
        }
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['rawSpreadsFromZero', 'priorityServices', 'dailyAnalysis', 'personalAccountManager', 'accessExclusiveWebinars', 'autochartistPerformanceStats', 'vipTradingAlerts'],
  },
  invest_plus:{
    details :{
      minDepositAmount: {value: 200, subtext: 'usd'},
      availableAssets: {value: '1000+', subtext: 'investPlusAssets'},
      optionWithLeverage: '(1:5)',
    },
    commission: {
      unleveragedSharesDma: {
        value: '$0',
        text: 'commissionValue'
      },
      etfleveragedSharesEtfds: {
        value: '0.1%',
        text: 'valueOnMarketPrice'
      },
    },
    benefits: ['twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'tradingCentralTools', 'basicAccessAutochartist', 'limitedAccessTradingAlerts', 'dividendsPaidShares'],
  },
  cent: {
    details: {
      minDepositAmountUsd: 10,
      averageSpread: {
        default: 1.6,
        bdSwissMauritius: {
          default: 1.6,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.6
            return acc
          }, {})
        },
        bdSwissVanuatu: {
          default: 1.6,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.6
            return acc
          }, {})
        },
        swissMarketsMauritius: {
          default: 1.6,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.6
            return acc
          }, {})
        },
        swissMarketsVanuatu: {
          default: 1.6,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.6
            return acc
          }, {})
        },
      },
      averageMetalsEnergiesSpread: 30,
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['upTo2000Leverage', 'twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'tradingCentralTools', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
  },
  premium: {
    details: {
      minDepositAmountUsd: 100,
      averageSpread: 1.1,
      averageMetalsEnergiesSpread: 30,
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLotZero'
      },
    },
    benefits: ['upToLeverage', 'twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'tradingCentralTools', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
    note: 'leverageNote',
  },
  black_zulu: {
    benefits: ['upToLeverage', 'twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'tradingCentralTools', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
  },
  standard: {
    details: {
      minDepositAmountUsd: {
        default: 100,
        bdSwissMauritius: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
        bdSwissVanuatu: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
        swissMarketsMauritius: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
        swissMarketsVanuatu: {
          default: 10,
          bh: 100,
          kw: 100,
          om: 100,
          qa: 100,
          sa: 100,
          ae: 100,
          eg: 100,
          iq: 100,
          il: 100,
          jo: 100,
          lb: 100,
          ye: 100,
          ps: 100
        },
      },
      averageSpread: {
        default: 1.3,
        bdSwissMauritius: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
        bdSwissVanuatu: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
        swissMarketsMauritius: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
        swissMarketsVanuatu: {
          default: 1.3,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.5
            return acc
          }, {})
        },
      }
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['twentyfourFiveSupport', 'dailyAnalysis', 'tradingAcademy', 'freeLiveWebinars', 'basicAccessAutochartist', 'limitedAccessTradingAlerts'],
  },
  pro: {
    details: {
      minDepositAmountUsd: {
        default: 3000,
        bdSwissMauritius: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
        bdSwissVanuatu: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
        swissMarketsMauritius: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
        swissMarketsVanuatu: {
          default: 250,
          bh: 3000,
          kw: 3000,
          om: 3000,
          qa: 3000,
          sa: 3000,
          ae: 3000,
          eg: 3000,
          iq: 3000,
          il: 3000,
          jo: 3000,
          lb: 3000,
          ye: 3000,
          ps: 3000
        },
      },
      averageSpread: {
        default: 1.0,
        bdSwissMauritius: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
        bdSwissVanuatu: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
        swissMarketsMauritius: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
        swissMarketsVanuatu: {
          default: 1.0,
          ...reduce(regions.mena.countries, (acc, country) => {
            acc[country.key] = 1.1
            return acc
          }, {})
        },
      },
    },
    commission: {
      forexMetals: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      energies: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      indices: {
        value: '$0',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['priorityServices', 'dailyAnalysis', 'personalAccountManager', 'accessExclusiveWebinars', 'autochartistPerformanceStats', 'vipTradingAlerts'],
  },
  prime:{
    details: {
      minDepositAmountUsd: {
        default: 5000,
        bdSwissMauritius: {
          default: 5000,
          bh: 5000,
          kw: 5000,
          om: 5000,
          qa: 5000,
          sa: 5000,
          ae: 5000,
          eg: 5000,
          iq: 5000,
          il: 5000,
          jo: 5000,
          lb: 5000,
          ye: 5000,
          ps: 5000
        },
        bdSwissVanuatu: {
          default: 5000,
          bh: 5000,
          kw: 5000,
          om: 5000,
          qa: 5000,
          sa: 5000,
          ae: 5000,
          eg: 5000,
          iq: 5000,
          il: 5000,
          jo: 5000,
          lb: 5000,
          ye: 5000,
          ps: 5000
        },
        swissMarketsMauritius: {
          default: 5000,
          bh: 5000,
          kw: 5000,
          om: 5000,
          qa: 5000,
          sa: 5000,
          ae: 5000,
          eg: 5000,
          iq: 5000,
          il: 5000,
          jo: 5000,
          lb: 5000,
          ye: 5000,
          ps: 5000
        },
        swissMarketsVanuatu: {
          default: 500,
          bh: 5000,
          kw: 5000,
          om: 5000,
          qa: 5000,
          sa: 5000,
          ae: 5000,
          eg: 5000,
          iq: 5000,
          il: 5000,
          jo: 5000,
          lb: 5000,
          ye: 5000,
          ps: 5000
        },
      },
      averageSpread: 0.3,
    },
    commission: {
      forexMetals: {
        value: '$5',
        text: 'equivalentPerLot',
        cn: {
          value: '$5',
        }
      },
      energies: {
        value: '$5',
        text: 'equivalentPerLot',
        cn: {
          value: '$5',
        }
      },
      indices: {
        value: '$2',
        text: 'equivalentPerLot'
      },
      equities: {
        value: '0.15%',
        text: 'ofVolume'
      },
    },
    benefits: ['rawSpreadsFromZero', 'priorityServices', 'dailyAnalysis', 'personalAccountManager', 'accessExclusiveWebinars', 'autochartistPerformanceStats', 'vipTradingAlerts'],
  },
}

export const documentHelperText ={
  poi:{
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
    residencePermit: {
      acceptedDocuments: ['acceptedDocumentsTextPersonal'],
      makeSure: ['makeSureDocumentsTextPersonal', 'makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
    }
  },
  por:{
    shouldInclude: ['makeSureDocumentsTextPor3Sub1', 'makeSureDocumentsTextPor3Sub2', 'makeSureDocumentsTextPor3Sub3', 'makeSureDocumentsTextPor3Sub4'],
    makeSure: ['makeSureDocumentsTextPor1', 'makeSureDocumentsTextPoi2'],
    acceptedDocuments: ['acceptedDocumentsTextPor1', 'acceptedDocumentsTextPor2', 'acceptedDocumentsTextPor3', 'acceptedDocumentsTextPor4', 'acceptedDocumentsTextPor5', 'hukouBook'],
  },
  pof:{
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
    card: {
      instructions: ['pofCardInstructions']
    },
    virtualCard: {
      instructions: ['pofVirtualCardInstructions']
    }
  },
  partner: {
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
  },
  personal: {
    makeSure: ['makeSureDocumentsTextPoi1', 'makeSureDocumentsTextPoi2', 'makeSureDocumentsTextPoi3'],
  }
}

export const commonIbanCountries = {
  IM: 'GB',
}

export const performanceFeeOptions = {
  options: [0, 5, 10, 15, 20, 25, 30, 35, 40 ,45, 50],
  default: 10,
}

export const serversLabel = {
  forexMt4: 'MT4',
  forexMt5: 'MT5',
  cent: 'MT4',
  centMt5: 'MT5',
}

export const referAndEarnDetails = {
  referrerInstructions: ['referAndEarnText1', 'referAndEarnText2', 'referAndEarnText3', 'referAndEarnText6', 'referAndEarnText7'],
  referralInstructions: ['congratulationsText2', 'referReferralText1', 'referReferralText2'],
  minDeposit: 100,
  days: 30,
  currency: '$',
  referEmailsFriends: 10,
  referredSomeone: ['congratulationsReferredText1', 'congratulationsReferredText2', 'congratulationsReferredText3', 'congratulationsReferredText4'],
}

export const isRtlLang = ['ar']

export const momentLocales = {
  no: 'nb',
  ph: 'tl-ph',
  zh: 'zh-cn',
  excluded:  ['ar', 'hi'],
  default: 'en'
}

export const eBookHelperText = {
  technicalIndicator:{
    points: ['technicalIndicatorListItem1', 'technicalIndicatorListItem2', 'technicalIndicatorListItem3',
      'technicalIndicatorListItem4', 'technicalIndicatorListItem5','technicalIndicatorListItem6',
      'technicalIndicatorListItem7', 'technicalIndicatorListItem8', 'technicalIndicatorListItem9'],
  },
  tradingPsychology:{
    points: ['tradingPsychologyListItem1', 'tradingPsychologyListItem2', 'tradingPsychologyListItem3',
      'tradingPsychologyListItem4', 'tradingPsychologyListItem5','tradingPsychologyListItem6',
      'tradingPsychologyListItem7', 'tradingPsychologyListItem8'],
  },
}

export const euRegulationHelperText = {
  bdSwiss: ['successfulMigrationText1', 'successfulMigrationText2', 'successfulMigrationText4'],
  swissMarkets: ['successfulMigrationText1', 'successfulMigrationText3', 'successfulMigrationText4']
}

export const wireTransferNotAvailableBanks = [
  {
    key: 'monsas',
    label: 'Monsas',
    startDate: '2021-08-20',
    activeDays: 42,
    availablePayments: ['bankWire'],
  }
]

export const partnerMigrationText = {
  bdSwiss: 'euBased'
}

export const accountCategoriesDetails = {
  cryptoWallet: {
    bitnuk: {
      dot: true,
      startDate: '2021-09-09',
      activeDays: 60,
      newBadge: true,
      benefits: ['bitwalletBenefit1', 'bitwalletBenefit2', 'bitwalletBenefit3', 'bitwalletBenefit4']
    }
  }
}

export const withdrawalWarnings = {
  bankTransfer: {
    title: 'bankTransferWithdrawalWarningTitle',
    content: ['bankTransferWithdrawalWarningText'],
  }
}

export const withdrawalPSPMessagesFilter = {
  chipPay: {
    content: [messages.chipPayWithdrawalDescription1.i18nKey],
    regulators: [companies.bdSwissMauritius.regulator, companies.bdSwissVanuatu.regulator],
  }
}

export const hideWithdrawalWarningsCountries = {
  in: {
    excluded: ['bankTransferWithdrawalDescription2', 'bankTransferWithdrawalDescription3', 'bankTransferWithdrawalDescription4']
  }
}

export const downloadMt5Notes = {
  fundYourAccountText: {
    key: 'fundYourAccountText',
    subContent: [{key: 'yourBalance'}],
  },
  downloadServerDesktopApplication: {
    key: 'downloadServerDesktopApplication',
  },
  openServerApplication: {
    key: 'openServerApplication',
  },
  addAccountLoginAccount: {
    key: 'addAccountLoginAccount',
  },
  enterAccountDetails: {
    key: 'fundYourAccountText',
    subContent: [{key: 'login'}, {key: 'password'}, {key: 'server', subContent: 'serverName'}],
  }
}

export const localesUpperNameFields = ['de']

export const fnsLocales = {
  no: 'nb',
  zh: 'zhCN',
  ar:  'arSA',
  default: 'enUS'
}

export const partnersLoyaltyProgressBarStatus = {
  red: {
    key: 'red',
    min: 0,
    max: 10,
  },
  yellow: {
    key: 'yellow',
    min: 11,
    max: 30,
  },
  green: {
    key: 'green',
    min: 31,
    max: 100,
  },
}

export const ageLimitWarning = 70

export const providersPayment= {
  // creditCard: {
  //   fee: 4
  // }
}

export const organicTraficParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid', 'msclkid', 'fbclid', 'am']

export const carouselBanners = {
  first:{
    lightPhoto: `${Images['bg-carousel-1.jpg']}`,
    darkPhoto: `${Images['bg-carousel-1-dark.jpg']}`,
    link: '/accounts',
    key: 'first',
    titleWidth: 12,
    bannerTitle: {
      key: 'bannerFirstTitle',
      show: true,
    },
    bannerSubTitle: {
      key: 'bannerFirstSubTitle',
      show: true,
    },
    bannerCaptionText:{
      key: 'bannerFirstCaptionText',
      show: false,
    },
    bannerButton: {
      key: 'bannerFirstButtonText',
      show: true,
      showTerms: true,
      path: '/partner-zone/loyalty-program',
      rtlEnabled: true,
      termsRtlEnabled: false,
    },
    mediaRtlEnabled: false,
  },
  second:{
    lightPhoto: `${Images['bg-carousel-2.jpg']}`,
    darkPhoto: `${Images['bg-carousel-2-dark.jpg']}`,
    link: '/tools',
    key: 'second',
    titleWidth: 6,
    bannerTitle: {
      key: 'bannerSecondTitle',
      show: true,
    },
    bannerSubTitle: {
      key: 'bannerSecondSubTitle',
      show: false,
    },
    bannerCaptionText:{
      key: 'bannerSecondCaptionText',
      show: false,
    },
    bannerButton: {
      key: 'bannerSecondButtonText',
      show: true,
      showTerms: false,
      path:'/partner-zone/events',
      rtlEnabled: false,
      termsRtlEnabled: true,
    },
    bannerListHorizontal: {
      key: 'bannerListHorizontal',
      show: true,
      items: ['bannerSecondListItem1', 'bannerSecondListItem2', 'bannerSecondListItem3'],
    },
    mediaRtlEnabled: true,
  },
  third:{
    lightPhoto: `${Images['bg-carousel-3.jpg']}`,
    darkPhoto: `${Images['bg-carousel-3-dark.jpg']}`,
    link: '/tools',
    key: 'third',
    titleWidth: 4,
    bannerTitle: {
      key: 'bannerThirdTitle',
      show: true,
    },
    bannerSubTitle: {
      key: 'bannerSecondSubTitle',
      show: false,
    },
    bannerCaptionText:{
      key: 'bannerSecondCaptionText',
      show: false,
    },
    bannerListVertical: {
      key: 'bannerListVertical',
      show: true,
      items: ['bannerThirdListItem1', 'bannerThirdListItem2', 'bannerThirdListItem3']
    },
    mediaRtlEnabled: true,
  },
  forth:{
    lightPhoto: `${Images['bg-carousel-4.jpg']}`,
    darkPhoto: `${Images['bg-carousel-4-dark.jpg']}`,
    link: '/tools',
    key: 'forth',
    titleWidth: 6,
    bannerTitle: {
      key: 'bannerForthTitle',
      show: true,
    },
    bannerButton: {
      key: 'bannerForthButtonText',
      show: true,
      showTerms: false,
      path: 'toIbPortal',
      rtlEnabled: false,
      termsRtlEnabled: true,
    },
    bannerListHorizontal: {
      key: 'bannerListHorizontal',
      show: true,
      items: ['bannerForthListItem1', 'bannerForthListItem2', 'bannerForthListItem3']
    },
    mediaRtlEnabled: true,
  },
}

export const localesReverseTextAccountTypes = {
  pt: ['basic', 'raw', 'black', 'cent', 'invest_plus']
}

export const profileSettingsCards = {
  profile: {
    key: 'profile',
    light: Images['icon-personal-profile-grey-light.svg'],
    dark: Images['icon-personal-profile-grey-dark.svg'],
    title: () => 'personalDetailsLowerCase',
    subtitle: () => 'profileSettingsCard',
    url: 'personal-profile',
    show: () => true
  },
  appropriatenessTest: {
    key: 'appropriatenessTest',
    light: Images['icon-approp-test-grey-light.svg'],
    dark: Images['icon-approp-test-grey-dark.svg'],
    title: () => 'appropriatenessTest',
    subtitle: () => 'apTestSettingsCard',
    url: 'appropriateness-test',
    show: (isCorporate, _, appropTestRequired, __, ___, ____, _____, ______, hideCoporateCards) => (!isCorporate || (isCorporate && hideCoporateCards)) && appropTestRequired
  },
  economicProfile: {
    key: 'economicProfile',
    light: Images['icon-economic-profile-grey-light.svg'],
    dark: Images['icon-economic-profile-grey-dark.svg'],
    title: () => 'economicProfile',
    subtitle: () => 'financialDetailsLowerCase',
    url: 'economic-profile',
    show: () => true
  },
  kyc: {
    key: 'kyc',
    light: Images['icon-documents-grey-light.svg'],
    dark: Images['icon-documents-grey-dark.svg'],
    title: () => 'verificationDocuments',
    subtitle: () => 'kycSettingsCard',
    url: 'documents',
    show: (isCorporate, _, __, ___, ____, _____, ______, _______, hideCoporateCards) => (!isCorporate || (isCorporate && hideCoporateCards))
  },
  deposited: {
    key: 'deposited',
    light: Images['icon-documents-grey-light.svg'],
    dark: Images['icon-documents-grey-dark.svg'],
    title: () => 'paymentDocuments',
    subtitle: () => 'depositVericationCard',
    url: 'documents/upload/pof',
    show: (_, _clientDeposited) => true
  },
  dueDiligence: {
    key: 'dueDiligence',
    light: Images['icon-approp-test-grey-light.svg'],
    dark: Images['icon-approp-test-grey-dark.svg'],
    title: () => 'dueDiligence',
    subtitle: () => 'dueDiligenceSettingsCard',
    url: 'due-diligence',
    show: (isCorporate, _, __, hasWalletProductAccount, showDueDiligence, ___, ____, _____, hideCoporateCards) => (!isCorporate || (isCorporate && hideCoporateCards)) && hasWalletProductAccount && showDueDiligence
  },
  spoa: {
    key: 'spoa',
    light: Images['icon-spoa-grey-light.svg'],
    dark: Images['icon-spoa-grey-dark.svg'],
    title: () => 'settingsSpoaTitle',
    subtitle: () => 'settingsSpoaCaption',
    url: 'spoa-authorization',
    show: (_, __, ___, ____, _____, canGrantSpoa) => canGrantSpoa
  },
  localDepositor: {
    key: 'localDepositor',
    light: Images['icon-local-depositor-grey-light.svg'],
    dark: Images['icon-local-depositor-grey-dark.svg'],
    title: () => 'localDepositor',
    subtitle: (_, localDepositorStatus) => localDepositorStatus === localDepositorStatuses.pendingRegistration.value
      ? 'localDepositorCaptionPendingRegistration' : 'localDepositorCaption',
    url: 'local-depositor',
    show: (_, __, ___, ____, _____, ______, _______, ________, _________, isLocalDepositor) => isLocalDepositor
  },
  suitabilityTest: {
    key: 'suitabilityTest',
    light: Images['icon-spoa-grey-light.svg'],
    dark: Images['icon-spoa-grey-dark.svg'],
    title: () => 'suitabilityAssessment',
    subtitle: () => 'settingsPortfolioManagementCaption',
    url: 'portfolio-management',
    show: (isCorporate, _, __, ___, ____, _____, hasPortfolioManagement, ______, hideCoporateCards) => (!isCorporate || (isCorporate && hideCoporateCards)) && hasPortfolioManagement
  },
  swapFree: {
    key: 'swapFree',
    light: Images['icon-swapfree-grey-light.svg'],
    dark: Images['icon-swapfree-grey-dark.svg'],
    title: () => 'swapFreeIslamicAccount',
    subtitle: () => 'swapFreeIslamicAccountText',
    show: (_, __, ___, ____, _____, ______, _______, allowSwapFree, _________, ________, kycApproved, automateSwapFree, swap_free_old_logic ) => (allowSwapFree && !(allowSwapFree && kycApproved && automateSwapFree)) || (swap_free_old_logic && allowSwapFree &&automateSwapFree)
  },
  ...map(corporateSteps, (step) => ({
    key: step.key,
    light: Images[`icon-${step.key}.svg`],
    dark: Images[`icon-${step.key}.svg`],
    title: (locale) => step.cardLabel.localization.t(locale),
    subtitle: (locale) => step.description.localization.t(locale),
    show: (isCorporate, _, __, ___, ____, _____, ______, _______, hideCoporateCards) => isCorporate && !hideCoporateCards,
    isCorporate: true,
  }))
}

export const platformLabels = {
  forexmt4: {
    title: 'tradingPlatformTooltipMT4Title',
    content: ['tradingPlatformTooltipMT4Option1', 'tradingPlatformTooltipMT4Option2', 'tradingPlatformTooltipMT4Option3',
      'tradingPlatformTooltipMT4Option4', 'tradingPlatformTooltipMT4Option5', 'tradingPlatformTooltipMT4Option6', 'tradingPlatformTooltipMT4Option7'],
  },
  forexmt5: {
    title: 'tradingPlatformTooltipMT5Title',
    content: ['tradingPlatformTooltipMT5Option1', 'tradingPlatformTooltipMT5Option2', 'tradingPlatformTooltipMT5Option3',
      'tradingPlatformTooltipMT5Option4', 'tradingPlatformTooltipMT5Option5', 'tradingPlatformTooltipMT5Option6',
      'tradingPlatformTooltipMT5Option7', 'tradingPlatformTooltipMT5Option8'],
  },
}

export const websiteLocales = ['en', 'de', 'es', 'fr', 'pl', 'ar', 'th', 'ph', 'id', 'pt', 'tr',
  'cs', 'it', 'ko', 'nb', 'da', 'ms', 'vi', 'hi', 'zh', 'ro', 'ru']

export const ibAppendix8 = ['ae', 'sa', 'bh', 'ir', 'iq', 'kw', 'qa', 'eg', 'om', 'lb', 'jo', 'sy', 'tr', 'ye']

export const bentoMenuOptions = {
  webtrader: {
    key: 'webtrader',
    iconWidth: '11px',
    iconWidthMobile: '14px',
    iconHeight: '6px',
    iconHeightMobile: '8px',
    lightIcon: Images['bento-webtrader-light.svg'],
    mobileLightIcon: Images['bento-webtrader-light.svg'],
    titleKey: 'bentoWebtraderTitle',
    firebaseEvent: 'WebTrader',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled && bentoMenu.options.webtrader.enabled,
    show: (_, tradingAccounts) => !isEmpty(tradingAccounts),
  },
  affiliate: {
    key: 'affiliate',
    iconWidth: '16px',
    iconWidthMobile: '14px',
    iconHeight: '16px',
    iconHeightMobile: '14px',
    lightIcon: Images['bento-affiliate-light.svg'],
    mobileLightIcon: Images['bento-affiliate-light.svg'],
    titleKey: 'bentoAffiliatePortalTitle',
    firebaseEvent: 'Affiliate Portal',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled && bentoMenu.options.affiliate.enabled,
    show: (viewer) => clientTypes.affiliate.value === viewer.clientType,
  },
  website: {
    key: 'website',
    iconWidth: '14px',
    iconWidthMobile: '14px',
    iconHeight: '14px',
    iconHeightMobile: '14px',
    lightIcon: Images['bento-website-light.svg'],
    mobileLightIcon: Images['bento-website-light.svg'],
    titleKey: 'bentoWebsiteTitle',
    firebaseEvent: 'Website',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled && bentoMenu.options.website.enabled,
    show: () => true,
  },
  ib: {
    key: 'ib',
    iconWidth: '14px',
    iconWidthMobile: '14px',
    iconHeight: '13px',
    iconHeightMobile: '13px',
    lightIcon: Images['bento-ib-light.svg'],
    mobileLightIcon: Images['bento-ib-light.svg'],
    titleKey: 'bentoIbPortalTitle',
    firebaseEvent: 'IB Portal',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled && bentoMenu.options.ib.enabled,
    show: (viewer) => clientTypes.ib.value === viewer.clientType,
  },
  divider1: {
    key: 'divider1',
    titleKey: 'divider',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled,
    show: () => true,
  },
  telegramGroup: {
    key: 'telegramGroup',
    iconWidth: '13px',
    iconWidthMobile: '13px',
    iconHeight: '11px',
    iconHeightMobile: '11px',
    lightIcon: Images['bento-telegram-light.svg'],
    mobileLightIcon: Images['bento-telegram-light.svg'],
    titleKey: 'bentoTelegramChannelTitle',
    subtitleKey: 'bentoTelegramChannelSubtitle',
    firebaseEvent: 'Telegram Channel',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled && bentoMenu.options.telegramGroup.enabled,
    show: () => true,
  },
  tradingCentral: {
    key: 'tradingCentral',
    iconWidth: '14px',
    iconWidthMobile: '14px',
    iconHeight: '14px',
    iconHeightMobile: '14px',
    lightIcon: Images['bento-tradingcentral-light.svg'],
    mobileLightIcon: Images['bento-tradingcentral-light.svg'],
    titleKey: 'bentoTradingCentralTitle',
    subtitleKey: 'bentoTradingCentralSubtitle',
    firebaseEvent: 'Trade Central',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled && bentoMenu.options.tradingCentral.enabled,
    show: () => true
  },
  mobileQR: {
    key: 'mobileQR',
    iconWidth: '14px',
    iconWidthMobile: '14px',
    iconHeight: '14px',
    iconHeightMobile: '14px',
    lightIcon: Images['bento-qrcode-light.svg'],
    mobileLightIcon: Images['bento-qrcode-mob.svg'],
    titleKey: 'bentoMobileQrCodeTitle',
    subtitleKey: 'bentoMobileQrCodeSubtitle',
    firebaseEvent: 'Mobile App',
    enabled: (bentoMenu) => bentoMenu && bentoMenu.enabled && bentoMenu.options.mobileQR.enabled,
    show: () => true,
  }
}

export const promotionCardDetails = {
  timeLeft: {
    key: 'timeLeft',
    title: () => 'timeLeftPromotionsText',
    value: (startDate, endDate, ___, ____, _____, ______, _______, ________, t) => {
      if (isNil(startDate) || isNil(endDate)) return ''
      startDate = moment(new Date(startDate)).isAfter(moment()) ? startDate : moment().toISOString()
      return momentDuration(startDate, endDate, t)
    },
  },
  minDeposit: {
    key: 'minDeposit',
    title: () => 'minDepositPromotionsText',
    value: (_, __, minimumDeposit, currency) => {
      if (isNil(minimumDeposit) || isNil(currency)) return ''
      return `${minimumDeposit} ${currency}`
    }
  },
  claimed: {
    key: 'claimed',
    title: () => 'claimedPromotionText',
    value: (_, __, ___, currency, received, maxBonus) => {
      if (isNil(received) || isNil(maxBonus) || isNil(currency)) return ''
      return `${received}/${maxBonus} ${currency}`
    }
  },
  bonus: {
    key: 'bonus',
    title: () => 'bonusPromotionText',
    value: (_, __, ___, currency, _____, ______, amountValue, amountType, _________) => {
      if (isNil(amountValue) || isNil(amountType) || isNil(currency)) return ''
      const amountTypeString = toLower(amountType) === 'percentage' ? '%' : toLower(amountType) === 'money' ? ` ${currency}` : ''
      return `${amountValue}${amountTypeString}`
    }
  },
}

export const warningNotiication = {
  nonComplOrReceipt: {
    buttonName: messages.upload,
    leadsTo: '/settings/profile/documents',
    disableBtn:[tradingStatuses.terminated.value],
  },
  missingKycDoc: {
    buttonName: messages.upload,
    leadsTo: '/settings/profile/documents',
  },
  missingProofAddress: {
    buttonName: messages.upload,
    leadsTo: '/settings/profile/documents',
  },
  missingProofOfId: {
    buttonName: messages.upload,
    leadsTo: '/settings/profile/documents',
  },
  missingProofOfPayment: {
    buttonName: messages.upload,
    leadsTo: '/settings/profile/documents',
  },
  pepOrUs: {
    buttonName: messages.complete,
    leadsTo: '/settings/profile/economic-profile',
  },
  declaredUsCitizen: {
    buttonName: messages.complete,
    leadsTo: '/settings/profile/economic-profile',
  },
  cardCopiesRequired: {
    buttonName: messages.upload,
    leadsTo: '/settings/profile/documents',
  },
  declaredPep: {
    buttonName: messages.complete,
    leadsTo: '/settings/profile/economic-profile',
  },
  resubmitEconomicProfile: {
    buttonName: messages.complete,
    leadsTo: '/settings/profile/economic-profile',
  },
  resubmitEconomicProfileKYC: {
    buttonName: messages.complete,
    leadsTo: '/settings/profile',
  },
}

export const withdrawNotificationLinks = {
  kycDocsLink: {
    key: 'kycDocsLink',
    path: '/settings/profile/documents',
    state: (_) => null,
    show: (showKycLink, __) => showKycLink,
    translationKey: 'uploadDocuments',
    showMissingAction: (_, __) => false,
    missingActionTranslationKey: '',
  },
  paymentDocsLink: {
    key: 'paymentDocsLink',
    path: '/settings/profile/documents/upload/pof',
    state: (state) => isObject(state) && !isEmpty(state) ? state : null,
    show: (_, showPaymentLink) => showPaymentLink,
    translationKey: 'paymentDocuments',
    showMissingAction: (_, showPaymentLink) => showPaymentLink,
    missingActionTranslationKey: 'paymentVerificationAction',
  },
}

export const registerWidgetLPTabs = [
  {
    key: 'existing',
    title: messages.existingClients,
    fields: ['useEmail', 'id', 'email', 'password'],
    requiredFields: ['id', 'email', 'password'],
    urlParamFields: ['campaign', 'subcampid', 'p', 'p1', 'p2', 'p3', 'p4', 'company', 'language', 'type', 'attendanceAction'],
  },
  {
    key: 'new',
    title: messages.newClients,
    fields: ['firstName', 'lastName', 'country', 'prefix', 'phone', 'email'],
    requiredFields: ['firstName', 'lastName', 'country', 'prefix', 'phone', 'email'],
    urlParamFields: ['campaign', 'subcampid', 'p', 'p1', 'p2', 'p3', 'p4', 'company', 'language', 'type', 'attendanceAction'],
  },
]

export const personalProfileTabs = {
  details: {
    key: 'details',
    title: messages.personalDetails,
    relativePath: '/details',
    show: () => true,
  },
  economic: {
    key: 'economic',
    title: messages.economicProfile,
    relativePath: '/economic',
    show: () => true,
  },
  localDepositor: {
    key: 'localDepositor',
    title: messages.localDepositor,
    relativePath: '/local-depositor',
    show: (viewer) => get(viewer, 'isLocalDepositor'),
  }
}

export const paymentsTabs = {
  deposit: {
    key: 'deposit',
    title: messages.deposit,
    relativePath: '/deposit',
    show: (viewer) => !disableDepositWithSpecificCountries(viewer),
  },
  withdrawal: {
    key: 'withdrawal',
    title: messages.withdraw,
    relativePath: '/withdraw',
    show: () => true,
  },
  localDepositorTransfer: {
    key: 'localDepositorTransfer',
    title: messages.ldTransfer,
    relativePath: '/local-depositor-transfer',
    show: (viewer) => get(viewer, 'isLocalDepositor'),
  },
  transfer: {
    key: 'transfer',
    title: messages.transfer,
    relativePath: '/transfer',
    show: () => true,
  },
  history: {
    key: 'history',
    title: messages.history,
    relativePath: '/history',
    show: () => true,
  },
}

export const acknowledgmentLD = [
  'ldClientAcknowledgmentFormP1', 'ldClientAcknowledgmentFormP2', 'ldClientAcknowledgmentFormP3',
  'ldClientAcknowledgmentFormP4', 'ldClientAcknowledgmentFormP5', 'ldClientAcknowledgmentFormP6',
  'ldClientAcknowledgmentFormP7'
]

export const toolsTabs = {
  tools: {
    key: 'tools',
    title: messages.tools,
    relativePath: '/tools',
    show: () => true,
  },
}

export const leverageTypeCards = {
  default: {
    key: 'default_leverage',
    title: messages.defaultLeverageWithNumber,
  },
  dynamic: {
    key: 'dynamic_leverage',
    title: messages.dynamicLeverageWithNumber,
  }
}
export const leverageTypeTooltip = {
  default: {
    key: 'default_leverage',
    title: () => messages.default,
    description: messages.defaultDescription,
  },
  dynamic: {
    key: 'dynamic_leverage',
    title: (showDifferentTitle) => showDifferentTitle ? messages.copyTradingNotAvailableonDynamicAddAccount : messages.dynamicLeverage,
    description: messages.dynamicDescription,
  }
}

export const copyTradingStepsActivation = [
  {icon: ProfileIcon, title: messages.registration, points: [messages.registrationP1, messages.registrationP2, messages.registrationP3]},
  {icon: SettingsIcon, title: messages.settingUpServer, points: [messages.settingUpServerP1, messages.settingUpServerP2]},
  {icon: LinkIcon, title: messages.linkAccount, points: [messages.linkAccountP1, messages.linkAccountP2, messages.linkAccountP3]},
  {icon: CopyTradingIcon, title: messages.startCopyTrading, points: [messages.startCopyTradingP1, messages.startCopyTradingP2]},
]

export const pelicanButtons = [
  {
    name: 'Apple',
    icon: <AppleIcon />,
    qrValue: 'https://apps.apple.com/gb/app/pelican-trading/id1141718043'
  },
  {
    name: 'Android',
    icon: <PlayStoreIcon />,
    qrValue: 'https://play.google.com/store/apps/details?id=com.pelicanexchange',
  },
  {
    name: 'PlayStore',
    icon: <ApkIcon />,
    qrValue: 'https://play.google.com',
    linkOnly: true
  },
]

export const promotionalBanners = {
  bannerResearchAnalysis:{
    title: messages.bannerResearchAnalysisTitle,
    subtitle: messages.bannerResearchAnalysisSubtitle,
    image: Images['bannerResearchAnalysis.png'],
    link: config.weblinks.websiteUrl + '/research-analysis/',
    show: () => true
  },
  bannerTradingAcademy:{
    title: messages.bannerTradingAcademyTitle,
    subtitle: messages.bannerTradingAcademySubtitle,
    image: Images['bannerTradingAcademy.png'],
    link: config.weblinks.websiteUrl + '/trading-academy/',
    show: (viewer) => isEmpty(viewer?.educationalVideosLevels) || !config.sidebar.educationalVideos.show,
  },
  bannerEducationalVideos:{
    title: messages.educationalVideosNew,
    subtitle: messages.bannerEducationhalVideosSubtitle,
    image: Images['bannerTradingAcademy.png'],
    link: config.frontendUrl + '/educational-videos/',
    show: (viewer) => !isEmpty(viewer?.educationalVideosLevels) && config.sidebar.educationalVideos.show,
  },
  bannerTelegramChannel:{
    title: messages.bannerTelegramTitle,
    subtitle: messages.bannerTelegramSubtitle,
    image: Images['bannerTelegramChannel.png'],
    show: () => true
  },
  bannerTradingTools:{
    title: messages.tradingToolsSmall,
    subtitle: messages.bannerTradingToolsSubtitle,
    image: Images['bannerTradingTools.png'],
    link: config.frontendUrl + '/trading-tools',
    show: () => true
  }

}

export const recentTransactionsColumns = [
  {
    key: 'type',
    align: 'left',
    message: messages.type,
  },
  {
    key: 'date',
    align: 'right',
    message: messages.date,
  },
  {
    key: 'amount',
    align: 'right',
    message: messages.amount,
  },
  {
    key: 'status',
    align: 'right',
    message: messages.status,
  },
]

export const transactionCategories = {
  deposit: {
    key: 'deposit',
    message: messages.deposit,
    icon: 'deposit_download.svg',
  },
  withdrawal: {
    key: 'withdrawal',
    message: messages.withdrawal,
    icon: 'withdraw_upload.svg',
  },
  transfer: {
    key: 'transfer',
    message: messages.transfer,
    icon: 'transfer_sync_arrows.svg',
  },
  ldTransfer: {
    key: 'ldTransfer',
    message: messages.ldTransfer,
    icon: 'transfer_sync_arrows.svg',
  },
}

export const socialMediaIcons = {
  facebook: {
    svgIcon: {
      light: (className) => <FacebookIcon className={className} />,
      dark: (className) => <FacebookIconDark className={className} />,
    },
  },
  instagram: {
    svgIcon: {
      light: (className) => <InstagramIcon className={className} />,
      dark: (className) => <InstagramIconDark className={className} />,
    }
  },
  linkedin: {
    svgIcon: {
      light: (className) => <LinkedinIcon className={className} />,
      dark: (className) => <LinkedinIconDark className={className} />,
    }
  },
  telegram: {
    svgIcon: {
      light: (className) => <TelegramIcon className={className} />,
      dark: (className) => <TelegramIconDark className={className} />,
    }
  },
  x: {
    svgIcon: {
      light: (className) => <XIcon className={className} />,
      dark: (className) => <XIconDark className={className} />,
    }
  },
  youtube: {
    svgIcon: {
      light: (className) => <YoutubeIcon className={className} />,
      dark: (className) => <YoutubeIconDark className={className} />,
    }
  },
  tiktok: {
    svgIcon: {
      light: (className) => <TiktokIcon className={className} />,
      dark: (className) => <TiktokIconDark className={className} />,
    }
  }
}
